import { post } from './api.js'
import { setParamSign } from '@/utils/auth'

// import {pubKey} from '@/utils/auth'
// const setPassword = (password) => {
//   let encrypt = new jsencrypt()
//   encrypt.setPublicKey(pubKey);
//   return encrypt.encrypt(password)
// }
export const getIndexData = (params, config) => { // 获取首页数据
  return post(`${process.env.VUE_APP_BASE_QRUSER_API}/qruser/console/main/get_maininfo`, params, config)
} 


export const getStatic = (params, config) => { // 扫码统计图表数据
  return post(`${process.env.VUE_APP_BASE_QRUSER_API}/qruser/console/statis/get_chat`, params, config)
}

export const getStaticList = (params, config) => { // 扫码统计列表数据
  return post(`${process.env.VUE_APP_BASE_QRUSER_API}/qruser/console/statis/get_qrcode_list`, params, config)
}


export const getUserInfo = (params, config) => { // 获取用户信息
  return post(`${process.env.VUE_APP_BASE_QRUSER_API}/qruser/auth/get_userinfo`, params, config)
} 


/**
 * vip 获取流量
 * @param {} data
 */
export const getUseInfo = (params, config) => {
  return post(`${process.env.VUE_APP_BASE_QRUSER_API}/qruser/console/main/get_maininfo`, params, config)
}



export const upload = (params, config) => { // 图片上传
  params = setParamSign(params)
  return post(`${process.env.VUE_APP_BASE_FILE_API}/file/upload`, params, config)
}





export const getTemplateConfig = (params, config) => { // 获取用户默认模板配置
  // config = Object.assign({ noData: true, noLoading: true, ...Authorization},config)
  return post(`${process.env.VUE_APP_BASE_QRUSER_API}/qruser/user/template/get_byid`, params, config)
}

// 子管理员


export const getAccountList = (params, config) => { // 获取切换账户
  return post(`${process.env.VUE_APP_BASE_QRUSER_API}/qruser/console/subuser/get_account_list`, params, config)
}

export const changeAccount = (params, config) => { // 切换账户
  return post(`${process.env.VUE_APP_BASE_QRUSER_API}/qruser/auth/change_login`, params, config)
}

export const applyPartner = (params, config) => { // 合伙人申请
  return post(`${process.env.VUE_APP_BASE_QRUSER_API}/qruser/partner/operator/add`, params, config)
}
export const getPartner = (params, config) => { // 合伙人信息
  return post(`${process.env.VUE_APP_BASE_QRUSER_API}/qruser/partner/operator/info`, params, config)
}

export const reApplyPartner = (params, config) => { // 重新合伙人申请
  return post(`${process.env.VUE_APP_BASE_QRUSER_API}/qruser/partner/operator/edit`, params, config)
}

export const addBank = (params, config) => { // 提交银行卡信息
  return post(`${process.env.VUE_APP_BASE_QRUSER_API}/qruser/partner/operator/add_bank`, params, config)
}

export const getMainInfo = (params, config) => { // 获取首页信息
  return post(`${process.env.VUE_APP_BASE_QRUSER_API}/qruser/partner/main/info`, params, config)
}

export const getChannelStatistics = (params, config) => { // 获取渠道统计
  return post(`${process.env.VUE_APP_BASE_QRUSER_API}/qruser/partner/spread/analysis_list`, params, config)
}

export const getPayStatistics = (params, config) => { // 获取支付统计
  return post(`${process.env.VUE_APP_BASE_QRUSER_API}/qruser/partner/pay/analysis_list`, params, config)
}

export const getPayOrder = (params, config) => { // 获取支付订单
  return post(`${process.env.VUE_APP_BASE_QRUSER_API}/qruser/partner/pay/order_list`, params, config)
}

export const getCommissionList = (params, config) => { // 获取佣金列表
  return post(`${process.env.VUE_APP_BASE_QRUSER_API}/qruser/partner/commission/list`, params, config)
}

export const applyCommission = (params, config) => { // 申请结算
  return post(`${process.env.VUE_APP_BASE_QRUSER_API}/qruser/partner/commission/apply`, params, config)
}

export const getSpreadList = (params, config) => { // 获取推广信息列表
  return post(`${process.env.VUE_APP_BASE_QRUSER_API}/qruser/partner/spread/list`, params, config)
}

export const addSpread = (params, config) => { // 新增推广信息
  return post(`${process.env.VUE_APP_BASE_QRUSER_API}/qruser/partner/spread/add`, params, config)
}

export const editSpread = (params, config) => { // 编辑推广信息
  return post(`${process.env.VUE_APP_BASE_QRUSER_API}/qruser/partner/spread/edit`, params, config)
}

export const deleteSpread = (params, config) => { // 删除推广信息
  return post(`${process.env.VUE_APP_BASE_QRUSER_API}/qruser/partner/spread/del`, params, config)
}






